import React, { useState, useEffect } from 'react';
import { Form, Card, Input, Button, Radio, Select, DatePicker, TimePicker, Checkbox, InputNumber, Row, Col } from 'antd';
import { Request } from "./api/axios";
import { db } from "./components/firestore/firestore";
import { MinusCircleOutlined, PlusOutlined, ClockCircleOutlined,  CloseCircleOutlined } from '@ant-design/icons';
import "antd/dist/antd.css";
import './App.css';

const { TextArea } = Input;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const dateFormat = 'DD-MMM-YYYY';

const onFinish = values => {
 
  var month_name = function(dt){
    const mlist = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC" ];
      return mlist[dt];
    };
  
  var docName = "";
  var successStat = "";
  if(values.startDate && values.startDate.date && values.startDate.date())
    docName = values.startDate.date()+"-"+month_name(values.startDate.month())+"-"+values.startDate.year();

    if(values.multiStartDate && values.multiStartDate.date && values.multiStartDate.date())
    docName = values.multiStartDate.date()+"-"+month_name(values.multiStartDate.month())+"-"+values.multiStartDate.year();

  values=JSON.parse(JSON.stringify(values));

  if(docName && docName.length>0 && values.eventDayType === "singleDayEvent")
    {
      successStat = db.collection('tap-events').doc(docName).collection('singleDayEvent').add(values);
    } else if(docName && docName.length>0 && values.eventDayType === "multiDayEvent")
    {
      successStat = db.collection('tap-events').doc(docName).collection('multiDayEvent').add(values);
    }

  console.log('DB Insertion', successStat) ;
};

const onFinishFailed = errorInfo => {
  console.log('Failed:', errorInfo);
};

const { Option } = Select;

function App() {
  const [onEventDayTypeChange, setOnEventDayTypeChange] = useState(null);
  const [eventTypeList, setEventTypeList] = useState(null);
  const [hasMoreShows, setHasMoreShows] = useState(null);
  const [entryType, setEntryType] = useState(null);
  const [multiEntryType, setMultiEntryType] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await Request.get("eventTypes.json");
      setEventTypeList(response.data.eventTypes) 
    };
      fetchData();
    },
    []);

    return (
      <div class="App">
        <Form
        {...layout}
        name="basic"
        initialValues={ {} }
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        >
          <Form.Item 
            name="eventDayType"
          >
            <Radio.Group onChange={setOnEventDayTypeChange} >
            <Radio value="singleDayEvent">Single Day</Radio>
            <Radio value="multiDayEvent">Multi-Day</Radio>
          </Radio.Group>
          </Form.Item>

          { onEventDayTypeChange && onEventDayTypeChange.target.value === "singleDayEvent" &&
            <div>
              <Form.Item
                name="eventType" 
                rules={[
                  {
                    required: true,
                    message: "Missing event type"
                  }
                ]}      
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  placeholder="Event Type*"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {eventTypeList?.map((event) => (
                          <Option key={event.id} value={event.id}>
                            {event.value}
                          </Option>
                        ))}
                </Select>
              </Form.Item>
              <Form.Item 
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Missing title"
                  }
                ]}
              >
                <Input placeholder="Title*" />
              </Form.Item>
              <Form.Item
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Missing description"
                  }
                ]}
              >
                <TextArea
                  placeholder="Description*"
                  autoSize={{ minRows: 1, maxRows: 3 }}
                />
              </Form.Item>
              <Form.List name="nameOfArtists">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field, index) => (
                        <Form.Item
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={['onChange', 'onBlur']}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message: "Please input an Artist name or delete this field.",                                
                              },
                            ]}
                            noStyle
                          >
                            <Input placeholder="Artist(s)" style={{ width: '60%' }} />
                          </Form.Item>
                          {fields.length > 0 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button"
                              style={{ margin: '0 8px' }}
                              onClick={() => {
                                remove(field.name);
                              }}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      {fields.length < 5 ? (
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => {
                              add();
                            }}
                            style={{ width: '60%' }}
                          >
                            <PlusOutlined /> Add Artist(s)
                          </Button>
                        </Form.Item>
                      ) : null}
                    </div>
                  );
                }}
              </Form.List>
              <Row>
                <Col span={5}>
                  <Form.Item 
                    name="startDate"
                    rules={[
                      {
                        required: true,
                      }
                    ]}
                  >
                    <DatePicker placeholder="Start Date" format={dateFormat} 
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item 
                    name="showTime"
                    rules={[
                      {
                        required: true,
                      }
                    ]}
                  >
                    <TimePicker placeholder="Start Time" use12Hours format="h:mm a" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Checkbox name="hasMoreShows"
                  onChange={setHasMoreShows}>Has Multiple Shows</Checkbox>
                </Col>
              </Row>
              { hasMoreShows && hasMoreShows.target.checked ?
                <Form.List name="otherShowTimes">
                  {(fields, { add, remove }) => {
                    return (
                      <div>
                        {fields.map((field, index) => (
                          <Row>
                            <Col span={6} offset ={5}>
                              <Form.Item
                                required={false}
                                key={field.key}
                              >
                                <Form.Item
                                  {...field}
                                  validateTrigger={['onChange', 'onBlur']}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input a Show Time or delete this field.",
                                    },
                                  ]}
                                  noStyle
                                >
                                  <TimePicker use12Hours format="h:mm a" /> 
                                </Form.Item>
                                {fields.length > 0 ? (
                                  <MinusCircleOutlined
                                    className="dynamic-delete-button"
                                    style={{ margin: '0 8px' }}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                  />
                                ) : null}
                              </Form.Item>
                            </Col>
                          </Row>
                        ))}
                        {fields.length < 3 ? (
                            <Row>
                            <Col span={4} offset ={5}>
                          <Form.Item >
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                            >
                              <ClockCircleOutlined /> Add Shows
                            </Button>
                          </Form.Item>
                          </Col>
                          </Row>
                        ) : null}
                      </div>
                  );
                }}
                </Form.List>
              :null }
              <Form.Item 
                name="venue"
                rules={[
                  {
                    required: true,
                    message: "Missing venue"
                  }
                ]}
              >
                <Input placeholder="Venue*"></Input>
              </Form.Item>
              <Form.Item 
                name="entryType"
              >
                <Radio.Group onChange={setEntryType} >
                  <Radio value="free">Free</Radio>
                  <Radio value="paid">Paid</Radio>
                  <Radio value="rsvp">RSVP</Radio>
                </Radio.Group>
              </Form.Item>
              { entryType && entryType.target.value === "free" ?
                <Form.Item 
                  name="source"
                  rules={[
                    {
                      required: true,
                      message: "Missing source"
                    }
                  ]}
                >
                  <Input placeholder="Source*"></Input>
                </Form.Item>
              :null }
              { entryType && entryType.target.value === "paid" ?
                <Row>
                  <Col span={4}>
                  <Form.Item name="currency"
                    rules={[
                      {
                        required: true,
                        message: "Missing currency"
                      }
                    ]}>
                    <Select placeholder ="Currency">
                      <Select.Option value="inr">INR</Select.Option>
                    </Select>
                  </Form.Item>
                  </Col>

                  <Col span={5}>
                  <Form.Item name="amount"
                    rules={[
                      {
                        required: true,
                        message: "Missing amount"
                      }
                    ]}>
                    <InputNumber
                      placeholder="Amount*"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      min = {0}
                    />
                  </Form.Item>
                  </Col>
                  <Col span={6}>
                  <Form.Item 
                    name="ticketingChannel"
                  >
                    <Input placeholder="Ticketing Channel"></Input>
                  </Form.Item>
                  </Col>
                </Row>
              :null }
              { entryType && entryType.target.value === "rsvp" ?
                <Row>
                  <Col span={5}>
                  <Form.Item name="registerVia"
                    rules={[
                      {
                        required: true,
                        message: "Missing register link"
                      }
                    ]}>
                    <Select placeholder ="Channel*">
                      <Select.Option value="email">Email</Select.Option>
                      <Select.Option value="facebook">Facebook</Select.Option>
                      <Select.Option value="instagram">Instagram</Select.Option>
                      <Select.Option value="number">Number</Select.Option>
                      <Select.Option value="url">URL</Select.Option>
                    </Select>
                  </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item 
                      name="link"
                      rules={[
                        {
                          required: true,
                          message: "Missing RSVP link"
                        }
                      ]}
                    >
                      <Input placeholder="Register via*"></Input>
                    </Form.Item>
                  </Col>
                </Row>
              :null }
              <Form.Item {...layout}>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </div>
          }
          { onEventDayTypeChange && onEventDayTypeChange.target.value === "multiDayEvent" ?
            <div>
            <Form.Item 
              name="multiTitle"
              rules={[
                {
                  required: true,
                  message: "Missing title"
                }
              ]}
            >
              <Input placeholder="Title*" />
            </Form.Item>
            <Form.Item
              name="multiDescription"
              rules={[
                {
                  required: true,
                  message: "Missing description"
                }
              ]}
            >
              <TextArea
                placeholder="Description*"
                autoSize={{ minRows: 1, maxRows: 3 }}
              />
            </Form.Item>
            <Form.Item 
              name="multiEntryType"
            >
              <Radio.Group onChange={setMultiEntryType} >
                <Radio value="free">Free</Radio>
                <Radio value="paid">Paid</Radio>
                <Radio value="rsvp">RSVP</Radio>
              </Radio.Group>
            </Form.Item>
            { multiEntryType && multiEntryType.target.value === "free" ?
              <Form.Item 
                name="multiSource"
                rules={[
                  {
                    required: true,
                    message: "Missing source"
                  }
                ]}
              >
                <Input placeholder="Source*"></Input>
              </Form.Item>
              :null }
            { multiEntryType && multiEntryType.target.value === "paid" ?
              <Row>
                <Col span={4}>
                  <Form.Item name="multiCurrency"
                    rules={[
                      {
                        required: true,
                        message: "Missing currency"
                      }
                    ]}>
                    <Select placeholder ="Currency">
                      <Select.Option value="inr">INR</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name="multiAmount"
                    rules={[
                      {
                        required: true,
                        message: "Missing amount"
                      }
                    ]}>
                    <InputNumber
                      placeholder="Amount*"
                      formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      min = {0}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item 
                    name="multiTicketingChannel"
                  >
                    <Input placeholder="Ticketing Channel"></Input>
                  </Form.Item>
                </Col>
              </Row>
            :null }
            { multiEntryType && multiEntryType.target.value === "rsvp" ?
              <Row>
                <Col span={5}>
                  <Form.Item name="multiRegisterVia"
                    rules={[
                      {
                        required: true,
                      }
                    ]}>
                    <Select placeholder ="Channel*">
                      <Select.Option value="email">Email</Select.Option>
                      <Select.Option value="facebook">Facebook</Select.Option>
                      <Select.Option value="instagram">Instagram</Select.Option>
                      <Select.Option value="number">Number</Select.Option>
                      <Select.Option value="url">URL</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={10}>
                  <Form.Item 
                    name="multiLink"
                    rules={[
                      {
                        required: true,
                        message: "Missing link"
                      }
                    ]}
                  >
                    <Input placeholder="Register via*"></Input>
                  </Form.Item>
                </Col>
              </Row>
            :null }

            <Card>
              <div class="DisplayMultiComponents">
              <Form.Item
                name="multiEventType" 
                rules={[
                  {
                    required: true,
                    message: "Missing event type"
                  }
                ]}      
              >
                <Select
                  showSearch
                  placeholder="Event Type*"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 200 }}
                >
                  {eventTypeList?.map((event) => (
                          <Option key={event.id} value={event.id}>
                            {event.value}
                          </Option>
                        ))}
                </Select>
              </Form.Item>
                  <Form.Item
                    name="multiArtists"
                    noStyle
                  >
                    <TextArea 
                      className="ArtistInput"
                      placeholder="Artist(s)* (Separate multiple artists with commas)"
                      autoSize={{ minRows: 1, maxRows: 2 }}
                    />
                  </Form.Item>
                  <Form.Item 
                    name="multiVenue"
                    rules={[
                      {
                        required: true,
                        message: "Missing venue"
                      }
                    ]}
                  >
                    <Input placeholder="Venue*"></Input>
                  </Form.Item>
                  <Form.Item 
                    name="multiStartDate"
                    rules={[
                      {
                        required: true,
                        message: "Missing start date"
                      }
                    ]}
                  >
                    <DatePicker placeholder="Start Date" format={dateFormat} 
                    />
                  </Form.Item>
                  <Form.Item 
                    name="multiShowTime"
                    rules={[
                      {
                        required: true,
                        message: "Missing start time"
                      }
                    ]}
                  >
                    <TimePicker placeholder="Start Time" use12Hours format="h:mm a" />
                  </Form.Item>
              </div>
              
            </Card>
            <Form.List name="multipleShowTimes">
              {(fields, { add, remove }) => {
                return (
                  <div style = {{width: '100%'}}>
                    {fields.map((field, index) => (
                      <Card 
                       key={field.key}
                       style={{"margin-top": '10px'}}
                      >
                        <div class="DisplayMultiComponents">
                          <Form.Item
                            {...field}
                            name={[field.name, 'moreEventType']}
                            fieldKey={[field.fieldKey, 'moreEventType']}   
                          >
                          <Select
                            showSearch
                            placeholder="Event Type*"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            style={{ width: 200 }}
                          >
                            {eventTypeList?.map((event) => (
                                    <Option key={event.id} value={event.id}>
                                      {event.value}
                                    </Option>
                                  ))}
                          </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'moreArtists']}
                            fieldKey={[field.fieldKey, 'moreArtists']}
                            noStyle
                          >
                            <TextArea
                              className="ArtistInput"
                              placeholder="Artist(s) - Separate multiple artists with commas"
                              autoSize={{ minRows: 1, maxRows: 2 }}
                            />
                          </Form.Item>
                          <Form.Item 
                            {...field}
                            name={[field.name, 'moreVenue']}
                            fieldKey={[field.fieldKey, 'moreVenue']}
                          >
                            <Input placeholder="Venue*"></Input>
                          </Form.Item>
                          <Form.Item 
                            {...field}
                            name={[field.name, 'moreStartDate']}
                            fieldKey={[field.fieldKey, 'moreStartDate']}
                            rules={[
                              {
                                required: true,
                                message: "Missing start date"
                              }
                            ]}
                          >
                            <DatePicker placeholder="Start Date" format={dateFormat} 
                            />
                          </Form.Item>
                          <Form.Item 
                            {...field}
                            name={[field.name, 'moreShowTime']}
                            fieldKey={[field.fieldKey, 'moreShowTime']}
                            rules={[
                              {
                                required: true,
                                message: "Missing start time"
                              }
                            ]}
                          >
                            <TimePicker placeholder="Start Time" use12Hours format="h:mm a" />
                          </Form.Item>
                        </div>
                        {fields.length > 0 ? (
                          <CloseCircleOutlined
                            className="CloseButton"
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        ) : null}
                      </Card>
                    ))}
                    <Form.Item >
                      <Button
                        type="dashed"
                        onClick={() => {
                          add();
                        }}
                        className="AddMoreButton"
                      >
                        <PlusOutlined /> Add More Events
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            </Form.List>

            <Form.Item {...layout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            </div>
            :null }
        </Form>
      </div>
    );
}

export default App;
